import React from "react";
import Typography from "@mui/material/Typography";
import { HOST_IMAGE_URL } from "../../constants";
import {
  CategoryDevelopmentApproachesItem,
  CategoryDevelopmentApproachesItemContent,
  CategoryDevelopmentApproachesItemInfo,
  CategoryDevelopmentApproachesList,
  CategoryDevelopmentApproachesWrapper,
} from "./style";
import Box from "@mui/material/Box";
import ArrowHeading from "../arrowHeading";
import MediumArrow from "../../assets/icons/MediumArrow";

const CategoryDevelopmentApproaches = ({ element }) => {
  return (
    <CategoryDevelopmentApproachesWrapper>
      <ArrowHeading
        dangerouslySetInnerHTML={{
          __html: element.title,
        }}
      />
      <CategoryDevelopmentApproachesList>
        {element?.developmentApproaches?.map((item) => {
          return (
            <CategoryDevelopmentApproachesItem key={item.id}>
              <CategoryDevelopmentApproachesItemInfo>
                <Box className="titleArrow">
                  <MediumArrow />
                </Box>
                <CategoryDevelopmentApproachesItemContent>
                  <Typography variant="h2" component="div" className="title">
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </CategoryDevelopmentApproachesItemContent>
              </CategoryDevelopmentApproachesItemInfo>
              {item.image.data === null ? null : (
                <img
                  src={`${HOST_IMAGE_URL + item?.image?.data?.attributes?.url}`}
                  alt={item?.image?.data?.attributes?.alternativeText}
                  title={item?.image?.data?.attributes?.caption}
                />
              )}
            </CategoryDevelopmentApproachesItem>
          );
        })}
      </CategoryDevelopmentApproachesList>
    </CategoryDevelopmentApproachesWrapper>
  );
};

export default CategoryDevelopmentApproaches;
