import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import palette from "../../theme/palette";
import theme from "../../theme";

export const CategoryInfrastructureWrapper = styled(Box)({
  maxWidth: "481px",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    marginTop: "60px",
    maxWidth: "unset",
  },

  "& .title": {
    color: palette.amethystSmoke,
    lineHeight: "42px",
    letterSpacing: "-0.02em",
    fontSize: "34px",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "32px",
      marginBottom: "8px",
    },
  },
});

export const CategoryInfrastructureTitle = styled(Typography)({
  color: palette.amethystSmoke,
  lineHeight: "42px",
  letterSpacing: "-0.02em",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    lineHeight: "32px",
    marginBottom: "8px",
  },
});

export const CategoryInfrastructureList = styled(Box)({
  display: "flex",
  flexWrap: "wrap",

  [theme.breakpoints.down("xl")]: {
    marginTop: "18px",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
  },
});

export const CategoryInfrastructureItem = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "26px 26px 0 0",
  padding: "16px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "22px",
  border: `1px solid ${palette.amethystSmoke}`,

  [theme.breakpoints.down("md")]: {
    marginTop: "16px",
    padding: "8px 12px",
    fontSize: "14px",
    fontWeight: 500,
  },
});
