import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "../components/container";
import Seo from "../components/seo";
import {
  CategoryTop,
  CategoryTopDescription,
  CategoryTopInfo,
  CategoryWrapper,
  CategoryDoubleBlock,
} from "../styles/category";
import { HOST_IMAGE_URL } from "../constants";
import CategoryAdvantages from "../components/categoryAdvantages/CategoryAdvantages";
import CategoryApproaches from "../components/categoryApproaches/CategoryApproaches";
import CategoryOurWorks from "../components/categoryOurWorks/CategoryOurWorks";
import CategoryTechnologyStack from "../components/categoryTechnologyStack/CategoryTechnologyStack";
import CategoryWorksLikeThis from "../components/categoryWorksLikeThis/CategoryWorksLikeThis";
import {
  SliderAdaptiveContainer,
  SliderAdaptiveWrapper,
  SliderBackground,
} from "../components/slider/styles";
import CategoryAdvantagesSlider from "../components/categoryAdvantages/CategoryAdvantagesSlider";
import { CategoryDoubleBlockIcon } from "../assets/icons/CategoryDoubleBlockIcon";
import CategoryApproachesSlider from "../components/categoryApproaches/CategoryApproachesSlider";
import CategoryDevelopmentApproaches from "../components/categoryDevelopmentApproaches/CategoryDevelopmentApproaches";
import CategoryDevelopmentApproachesSlider from "../components/categoryDevelopmentApproaches/CategoryDevelopmentApproachesSlider";
import ArrowHeading from "../components/arrowHeading";
import { useBreakpointValue } from "../hooks/use-breakpoint-value";
import CategoryLeftInfo from "../components/categoryLeftInfo/CategoryLeftInfo";
import CategoryRightInfo from "../components/categoryRightInfo/CategoryRightInfo";

const Category = ({
  data: {
    strapi: { category },
  },
}) => {
  const isMobile = useBreakpointValue(["smallMobile", "mobile"]);

  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  return (
    <Layout>
      <Seo
        title={category?.data?.attributes?.seo?.metaTitle}
        description={category?.data?.attributes?.seo?.metaDescription}
        image={
          category?.data?.attributes?.seo?.metaImage?.data?.attributes?.url
        }
      />
      <Container direction="column">
        <CategoryWrapper>
          <CategoryTop>
            <CategoryTopInfo>
              <Typography variant="h1" fontWeight="600">
                {category?.data?.attributes?.title}
              </Typography>
              <CategoryTopDescription
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{
                  __html: category?.data?.attributes?.description,
                }}
              />
            </CategoryTopInfo>
            <img
              src={`${
                HOST_IMAGE_URL +
                category?.data?.attributes?.image?.data?.attributes?.url
              }`}
              alt={
                category?.data?.attributes?.image?.data?.attributes
                  ?.alternativeText
              }
              title={
                category?.data?.attributes?.image?.data?.attributes?.caption
              }
            />
          </CategoryTop>
          {/* CATEGORY ADVANTAGES */}
          {category?.data?.attributes?.elements?.map((element) => {
            if (element?.__typename === "strapi_ComponentCategoryAdvantages") {
              if (isMobile) {
                return (
                  <SliderAdaptiveContainer key={element.id}>
                    <CategoryAdvantagesSlider element={element} />
                    <SliderBackground />
                  </SliderAdaptiveContainer>
                );
              } else {
                return (
                  <CategoryAdvantages key={element.id} element={element} />
                );
              }
            }
            return null;
          })}
          {/* CATEGORY OUR WORKS */}
          {category?.data?.attributes?.elements?.map((element) => {
            if (element?.__typename === "strapi_ComponentCategoryOurWorks") {
              return (
                <CategoryOurWorks
                  key={element.id}
                  element={element}
                  cases={category?.data?.attributes?.cases}
                  slug={category?.data?.attributes?.slug}
                />
              );
            }
            return null;
          })}
          {/* CATEGORY APPROACHES */}
          {category?.data?.attributes?.elements?.map((element) => {
            if (element?.__typename === "strapi_ComponentCategoryApproaches") {
              if (isMobile) {
                if (element?.approaches?.length > 3) {
                  return (
                    <SliderAdaptiveWrapper key={element.id}>
                      <ArrowHeading>
                        <Typography
                          variant="h2"
                          dangerouslySetInnerHTML={{
                            __html: element.title,
                          }}
                        />
                      </ArrowHeading>
                      <SliderAdaptiveContainer className="approaches">
                        <CategoryApproachesSlider
                          key={element.id}
                          element={element}
                        />
                        <SliderBackground className="approaches" />
                      </SliderAdaptiveContainer>
                    </SliderAdaptiveWrapper>
                  );
                } else {
                  return (
                    <CategoryApproaches key={element.id} element={element} />
                  );
                }
              } else {
                return (
                  <CategoryApproaches key={element.id} element={element} />
                );
              }
            }
            return null;
          })}
          {/* CATEGORY DEVELOPMENT APPROACHES */}
          {category?.data?.attributes?.elements?.map((element) => {
            if (
              element?.__typename ===
              "strapi_ComponentCategoryDevelopmentApproaches"
            ) {
              if (isMobile) {
                return (
                  <SliderAdaptiveWrapper key={element.id}>
                    <ArrowHeading>
                      <Typography
                        variant="h2"
                        dangerouslySetInnerHTML={{
                          __html: element.title,
                        }}
                      />
                    </ArrowHeading>
                    <SliderAdaptiveContainer className="approaches">
                      <CategoryDevelopmentApproachesSlider
                        key={element.id}
                        element={element}
                      />
                      <SliderBackground className="approaches" />
                    </SliderAdaptiveContainer>
                  </SliderAdaptiveWrapper>
                );
              } else {
                return (
                  <CategoryDevelopmentApproaches
                    key={element.id}
                    element={element}
                  />
                );
              }
            }
            return null;
          })}
          {/* CATEGORY TECHNOLOGY STACK */}
          {category?.data?.attributes?.elements?.map((element) => {
            if (
              element?.__typename === "strapi_ComponentCategoryStackTechnology"
            ) {
              return (
                <CategoryTechnologyStack
                  key={element.id}
                  element={element}
                  stackTechnologies={
                    category?.data?.attributes?.stackTechnologies
                  }
                />
              );
            }
            return null;
          })}
          <CategoryDoubleBlock>
            {/* CATEGORY TEAM */}
            {category?.data?.attributes?.elements?.map((element) => {
              if (element?.__typename === "strapi_ComponentCategoryLeftInfo") {
                return (
                    <CategoryLeftInfo key={element.id} element={element} />
                )
              }
              return null;
            })}
            {/* CATEGORY INFRASTRUCTURE */}
            {category?.data?.attributes?.elements?.map((element) => {
              if (
                element?.__typename === "strapi_ComponentCategoryRightInfo"
              ) {
                return (
                  <CategoryRightInfo key={element.id} element={element} />
                );
              }
              return null;
            })}
            {!isTablet && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  left: "0px",
                  right: "105px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CategoryDoubleBlockIcon />
              </Box>
            )}
          </CategoryDoubleBlock>
          {/* CATEGORY WORKS LIKE THIS */}
          {category?.data?.attributes?.elements?.map((element) => {
            if (
              element?.__typename === "strapi_ComponentCategoryWorksLikeThis"
            ) {
              return (
                <CategoryWorksLikeThis key={element.id} element={element} />
              );
            }
            return null;
          })}
        </CategoryWrapper>
      </Container>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query categoryQuery($id: ID!) {
    strapi {
      category(id: $id) {
        data {
          id
          attributes {
            seo {
              ...seo
            }
            slug
            title
            description
            image {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            cases {
              data {
                attributes {
                  slug
                  title
                  description
                  mainImage {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  mainCategory {
                    data {
                      attributes {
                        preview {
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
            stackTechnologies {
              data {
                attributes {
                  icon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
            }
            elements {
              __typename

              ... on strapi_ComponentCategoryAdvantages {
                id
                isShowMalevichImage
                advantages {
                  id
                  title
                  description
                }
              }

              ... on strapi_ComponentCategoryOurWorks {
                id
                title
                description
              }

              ... on strapi_ComponentCategoryApproaches {
                id
                title
                isShowApproachesImage
                approaches {
                  id
                  title
                  description
                  icon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                  hoverIcon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }

              ... on strapi_ComponentCategoryDevelopmentApproaches {
                id
                title
                developmentApproaches {
                  id
                  title
                  description
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }

              ... on strapi_ComponentCategoryStackTechnology {
                id
                title
              }
              
              ... on strapi_ComponentCategoryLeftInfo {
                id
                title
                leftInfoItems {
                  id
                  title
                }
              }
              
              ... on strapi_ComponentCategoryRightInfo {
                id
                title
                rightInfoItems {
                  id
                  title
                }
              }
              
              ... on strapi_ComponentCategoryWorksLikeThis {
                id
                description
                sections {
                  id
                  title
                }
                items {
                  id
                  title
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
