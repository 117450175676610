import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import theme from "../../theme";

export const CategoryTechnologyStackWrapper = styled(Box)({
  paddingTop: "120px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "60px",
  },
});

export const CategoryTechnologyStackList = styled(Box)({
  display: "grid",
  gridTemplateColumns: " repeat(auto-fill, minmax(74px, 1fr))",
  gap: "34px 17px",
  alignItems: "baseline",
  justifyContent: "center",
  marginTop: "44px",

  [theme.breakpoints.down("xl")]: {
    position: "relative",
    height: "332px",
    flexWrap: "wrap",
    marginTop: "48px",
    overflowX: "scroll",
    overflowY: "hidden",
    width: "auto",
    whiteSpace: "nowrap",
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "32px",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
});

export const CategoryTechnologyStackItem = styled(Box)({
  width: "74px",
  margin: "0px auto",

  [theme.breakpoints.down("xl")]: {
    minWidth: "74px",
  },
});

export const TechnologyStackInfo = styled(Box)({
  display: "none",

  [theme.breakpoints.down("xl")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "16px 0",

    "& .firstArrowIcon": {
      transform: "rotate(180deg)",
    },

    "& .cursorIcon": {
      margin: "0 12px",
    },
  },

  "@media (max-width: 375px)": {
    "& .firstArrowIcon svg, & .secondArrowIcon svg": {
      width: "100%",
    },

    "& .cursorIcon": {
      margin: "0 6px",
    },
  },
});

export const CategoryTechnologyStackListMobile = styled(Box)({
  [theme.breakpoints.down("xl")]: {
    height: "308px",
    minWidth: "1435px",
    display: "grid",
    gridTemplateColumns: " repeat(auto-fill, minmax(74px, 1fr))",
    gap: "34px 17px",
    alignItems: "baseline",
    justifyContent: "center",
  },
});
