import Box from "@mui/material/Box";
import React from "react";
import { TeamWorksIcon } from "../../assets/icons/TeamWorksIcon";
import { useBreakpointValue } from "../../hooks/use-breakpoint-value";
import ArrowHeading from "../arrowHeading";
import {
  CategoryTeamWrapper,
  CategoryTeamList,
  CategoryTeamItem,
} from "./style";

const CategoryLeftInfo = ({ element }) => {
  const isMobile = useBreakpointValue(["smallMobile", "mobile", "bigMobile"]);

  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  return (
    <CategoryTeamWrapper>
      <ArrowHeading maxWidth="54px" className="title">
          {element?.title}
      </ArrowHeading>
      <Box
        display="flex"
        alignItems="center"
        marginTop={isMobile ? "10px" : "18px"}
      >
        <CategoryTeamList maxWidth="358px">
          {element?.leftInfoItems?.map((item) => {
            return (
              <CategoryTeamItem key={item.id}>{item.title}</CategoryTeamItem>
            );
          })}
        </CategoryTeamList>
        {!isTablet && <TeamWorksIcon />}
      </Box>
    </CategoryTeamWrapper>
  );
};

export default CategoryLeftInfo;
