import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../theme/palette";
import theme from "../../theme";

export const CategoryApproachesWrapper = styled(Box)({
  paddingTop: "120px",

  [theme.breakpoints.down("md")]: {
    paddingTop: "60px",
  },
});

export const CategoryApproachesLess = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "60px",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "normal",
    marginTop: 0,

    "& .lessImage": {
      display: "none",
    },
  },
});

export const CategoryApproachesList = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  marginTop: "36px",

  "&.vertical": {
    flexDirection: "column",
    justifyContent: "normal",
    marginTop: 0,
  },
});

export const CategoryApproachesItem = styled(Box)({
  maxWidth: "364px",
  width: "100%",
  marginBottom: "44px",
  padding: "26px",

  "& .description": {
    paddingTop: "28px",
    borderTop: `1px solid ${palette.amethystSmoke}`,
  },

  "& .hoverIcon": {
    display: "none",
  },

  "&:hover": {
    color: palette.secondary,
    background: palette.primary,

    "& .icon": {
      display: "none",
    },

    "& .hoverIcon": {
      display: "flex",
    },

    "& .cube": {
      "& svg path": {
        fill: palette.purpleBlue,
      },
    },
  },

  "&.vertical": {
    display: "flex",
    maxWidth: "492px",
    padding: "0",

    "&:last-of-type": {
      marginBottom: 0,
    },

    "& .titleArrow": {
      marginRight: "44px",
      lineHeight: "44px",
    },

    "& .title": {
      color: palette.amethystSmoke,
      lineHeight: "42px",
      letterSpacing: "-0.02em",
    },

    "& .description": {
      maxWidth: "320px",
      width: "100%",
      paddingTop: "16px",
      borderTop: "none",
    },

    "&:hover": {
      color: "inherit",
      background: "inherit",
    },
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "49%",
    border: `1px solid ${palette.amethystSmoke}`,
    pointerEvents: "none",

    "&.vertical": {
      border: "none",

      "& .titleArrow": {
        display: "none",
      },
    },
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",

    "&.vertical": {
      maxWidth: "100%",
      margin: "26px 0 0",

      "& .description": {
        maxWidth: "100%",
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
    marginBottom: 0,
    backgroundColor: palette.secondary,

    "&.vertical": {
      "& .title": {
        fontSize: "34px",
        lineHeight: "42px",
      },

      "& .description": {
        fontSize: "16px",
        lineHeight: "22px",
      },
    },
  },

  "@media (max-width: 375px)": {
    padding: 16,
  },
});

export const CategoryApproachesRowImage = styled(Box)({
  display: "flex",
  justifyContent: "center",
  maxWidth: "558px",
  width: "100%",
  height: "228px",
  marginTop: "-75px",

  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
});

export const CategoryApproachesItemTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "26px",
});
