import React from "react";

export const PineChartsIcon = () => {
  return (
    <svg
      width="537"
      height="544"
      viewBox="0 0 537 544"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M254.752 89.4062C152.756 89.4062 70.501 171.661 70.501 273.657C70.501 375.653 152.756 457.908 254.752 457.908C356.748 457.908 439.002 375.653 439.002 273.657C439.002 171.661 356.748 89.4062 254.752 89.4062ZM254.752 327.123C225.14 327.123 201.286 303.269 201.286 273.657C201.286 244.045 225.14 220.191 254.752 220.191C284.363 220.191 308.217 244.045 308.217 273.657C308.217 303.269 285.186 327.123 254.752 327.123Z"
        fill="#5A32FB"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M308.22 278.593C307.397 294.221 299.172 307.382 287.656 316.43L365.798 420.071C406.103 389.637 433.247 343.574 438.182 290.108L308.22 278.593Z"
        fill="#EBF2F7"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M207.519 298.67C200.16 284.95 198.244 261.834 212.169 242.467L88.8784 195.714C62.147 239.414 67.0854 310.192 91.3605 358.084L207.519 298.67Z"
        fill="#EBF2F7"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M205.952 228.766C213.502 215.058 226.64 206.796 240.948 203.67L215.774 76.3363C166.236 86.1673 121.733 115.8 93.9938 161.772L205.952 228.766Z"
        fill="#EBF2F7"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M250.928 219.586C266.491 217.942 280.781 223.997 291.527 233.947L381.596 140.484C345.204 105.465 295.448 85.8989 241.871 89.4295L250.928 219.586Z"
        fill="white"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M308.682 233.745C320.15 244.395 324.978 259.145 324.54 273.783L454.177 280.249C456.657 229.806 438.705 179.444 400.834 141.382L308.682 233.745Z"
        fill="#EDEDF8"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M355.914 439.015L282.496 331.776C258.062 348.504 224.904 342.297 208.176 317.863C207.246 316.505 206.317 315.148 205.388 313.79L91.1791 377.027C93.7531 382.243 97.0058 386.994 100.258 391.745C157.413 475.228 271.752 496.634 355.914 439.015Z"
        fill="#EDEDF8"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M409.5 324L409.5 424L512.5 424"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M282.5 445L282.5 521L145.5 521"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M423.5 233L423.5 152L536.5 152"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M113.5 295L113.5 248L0.500004 248"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M118.5 1L165.5 1V114"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M348.5 40L301.5 40L301.5 153"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <circle cx="423.5" cy="233" r="5" fill="#151515" />
      <circle cx="409.5" cy="324" r="5" fill="#151515" />
      <circle cx="282.5" cy="444" r="5" fill="#151515" />
      <circle cx="113.5" cy="295" r="5" fill="#151515" />
      <circle cx="165.5" cy="114" r="5" fill="#151515" />
      <circle cx="301.5" cy="148" r="5" fill="#151515" />
    </svg>
  );
};
