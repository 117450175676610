import React from "react";
import {
  CategoryWorksLikeThisWrapper,
  CategoryWorksLikeThisContent,
  CategoryWorksLikeThisSections,
  CategoryWorksLikeThisSectionsItem,
  CategoryWorksLikeThisDescription,
  CategoryWorksLikeThisList,
  CategoryWorksRightWrapper,
  TechnologyStackInfo,
  CategoryWorksLikeThisSectionsWrapper,
} from "./style";
import { PineChartsIcon } from "../../assets/icons/PineChartsIcon";
import { MalevichFiguresIcon } from "../../assets/icons/MalevichFiguresIcon";
import ArrowHeading from "../arrowHeading";
import { PineChartsMobileIcon } from "../../assets/icons/PineChartsMobileIcon";
import CategoryWorksInfoItem from "./CategoryWorksInfoItem";
import { ArrowTechnologyStackIcon } from "../../assets/icons/ArrowTechnologyStackIcon";
import { CursorIcon } from "../../assets/icons/CursorIcon";
import Box from "@mui/material/Box";
import { useBreakpointValue } from "../../hooks/use-breakpoint-value";

const CategoryWorksLikeThis = ({ element }) => {
  const isMobile = useBreakpointValue(["smallMobile", "mobile", "bigMobile"]);

  const isSmallMobile = useBreakpointValue(["smallMobile"]);

  return (
    <CategoryWorksLikeThisWrapper>
      <ArrowHeading>
        It works <strong>like this</strong>
      </ArrowHeading>
      <CategoryWorksLikeThisContent>
        <CategoryWorksLikeThisSectionsWrapper>
          <CategoryWorksLikeThisSections>
            {element?.sections?.map((section) => {
              return (
                <CategoryWorksLikeThisSectionsItem
                  variant="body1"
                  component="div"
                  key={section.id}
                >
                  {section.title}
                </CategoryWorksLikeThisSectionsItem>
              );
            })}
            {isMobile ? <PineChartsMobileIcon /> : <PineChartsIcon />}
          </CategoryWorksLikeThisSections>
          {isSmallMobile && (
            <TechnologyStackInfo>
              <Box className="firstArrowIcon">
                <ArrowTechnologyStackIcon />
              </Box>
              <Box className="cursorIcon">
                <CursorIcon />
              </Box>
              <Box className="secondArrowIcon">
                <ArrowTechnologyStackIcon />
              </Box>
            </TechnologyStackInfo>
          )}
        </CategoryWorksLikeThisSectionsWrapper>
        <CategoryWorksRightWrapper>
          <MalevichFiguresIcon />
          <CategoryWorksLikeThisDescription
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{
              __html: element.description,
            }}
          />
        </CategoryWorksRightWrapper>
      </CategoryWorksLikeThisContent>
      <CategoryWorksLikeThisList>
        {element?.items?.map((item) => {
          return <CategoryWorksInfoItem item={item} key={item.id} />;
        })}
      </CategoryWorksLikeThisList>
    </CategoryWorksLikeThisWrapper>
  );
};

export default CategoryWorksLikeThis;
