import React from "react";

export const TeamWorksIcon = () => {
  return (
    <svg
      width="49"
      height="83"
      viewBox="0 0 49 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2604 32.861C22.9126 25.381 12.7491 13.7522 17.2091 1.80721C17.2091 1.80721 24.3404 -2.96614 35.6323 3.00248L46.932 9.00195L33.2604 32.861Z"
        fill="#151515"
      />
      <path
        d="M48.1801 42.7254L28.3445 82.0357C25.7708 80.78 20.0441 77.7264 14.4438 72.9644C8.4924 67.9039 2.7334 60.9568 0.995258 52.234L0.995161 52.2335C-0.511074 44.7134 1.60168 35.8202 4.86337 27.6143C7.80053 20.2249 11.6436 13.4555 14.5088 8.85369C14.8497 14.4269 17.6532 19.5299 21.0595 23.7575C24.814 28.4173 29.3466 32.071 32.2895 34.1973C37.1536 37.7897 42.5052 40.6615 48.1801 42.7254Z"
        fill="white"
        stroke="#151515"
      />
      <path
        d="M11.4287 19.1582C12.0734 23.0423 13.6016 26.7246 15.8943 29.9186C18.187 33.1125 21.1824 35.7319 24.6474 37.573"
        stroke="#151515"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.041 41.8594L42.9778 48.2907"
        stroke="#151515"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9912 53.1738L29.4977 64.7263"
        stroke="#151515"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99805 32.0781C7.99805 32.0781 11.8362 40.6455 24.1952 46.2131"
        stroke="#151515"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.32227 37.5723C3.32227 37.5723 3.75213 46.5715 9.71666 49.5712"
        stroke="#151515"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9711 6.31055C16.9711 6.31055 15.8657 17.8777 33.2603 32.8609"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3652 8.4541C23.3652 8.4541 28.9076 8.02228 37.0061 14.0217"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
