import React from "react";
import palette from "../../theme/palette";

const Cube = ({ width = 22, height = 22 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 0.000693305L22 0V21.9993L0 22V0.000693305Z"
                fill={palette.primary}
            />
        </svg>
    );
};

export default Cube;
