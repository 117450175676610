import React, { useState } from "react";
import { CategoryWorksLikeThisListItem } from "./style";
import Typography from "@mui/material/Typography";
import { AltArrowDownIcon } from "../../assets/icons/AltArrowDownIcon";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

const CategoryWorksInfoItem = ({ item }) => {
  const [toggleInfo, setToggleInfo] = useState(false);

  const handleToggleInfo = () => {
    setToggleInfo((prev) => !prev);
  };
  return (
    <CategoryWorksLikeThisListItem key={item.id} onClick={handleToggleInfo}>
      <Box
        className="title"
        sx={{
          "& svg": {
            transform: toggleInfo ? "rotate(360deg)" : "rotate(180deg)",
          },
        }}
      >
        <Typography variant="body1" component="div">
          {item.title}
        </Typography>
        <AltArrowDownIcon />
      </Box>
      <Collapse in={toggleInfo}>
        <Typography variant="body2" component="div" className="description">
          {item.description}
        </Typography>
      </Collapse>
    </CategoryWorksLikeThisListItem>
  );
};

export default CategoryWorksInfoItem;
