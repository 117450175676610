import { styled } from "@mui/system";
import { Box } from "@mui/material";
import palette from "../../theme/palette";
import theme from "../../theme";

export const CategoryDevelopmentApproachesWrapper = styled(Box)({
  paddingTop: "120px",

  [theme.breakpoints.down("lg")]: {
    paddingTop: "60px",
  },
});

export const CategoryDevelopmentApproachesList = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
});

export const CategoryDevelopmentApproachesItem = styled(Box)({
  maxWidth: "47%",
  width: "100%",
  marginTop: "44px",

  "& .titleArrow": {
    display: "flex",
    alignItems: "center",
    height: "120px",
    marginRight: "44px",
  },

  "& .title": {
    display: "flex",
    alignItems: "center",
    height: "120px",
    lineHeight: "120%",
    letterSpacing: "-0.01em",
  },

  "& .description": {
    paddingTop: "22px",
  },

  "&:first-of-type": {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",

    "& .titleArrow": {
      height: "60px",
    },

    "& .title": {
      height: "60px",
    },
  },

  [theme.breakpoints.down("xl")]: {
    "& .titleArrow": {
      display: "none",
    },

    "&:first-of-type": {
      flexDirection: "column",

      "& img": {
        marginTop: "35px",
      },
    },
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
    marginTop: "26px",

    "& .title": {
      height: "auto",
    },

    "& .description": {
      paddingTop: "16px",
    },

    "&:first-of-type": {
      "& .title": {
        height: "auto",
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
    margin: 0,
    padding: "26px",
    backgroundColor: palette.secondary,
    border: `1px solid ${palette.amethystSmoke}`,

    "& .title": {
      marginBottom: "12px",
      fontSize: "24px",
      lineHeight: "32px",
    },

    "& .description": {
      borderTop: `1px solid ${palette.amethystSmoke}`,
    },

    "&:nth-of-type(2)": {
      display: "none",
    },
  },

  "@media (max-width: 375px)": {
    padding: 16,
  },
});

export const CategoryDevelopmentApproachesItemInfo = styled(Box)({
  display: "flex",
});

export const CategoryDevelopmentApproachesItemContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
