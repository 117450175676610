import React from "react";

export const MalevichFiguresIcon = () => {
  return (
    <svg
      width="42"
      height="45"
      viewBox="0 0 42 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.995 31.2972C32.3607 31.2972 32.6773 31.0243 32.7241 30.6498L33.438 24.9637C33.4892 24.5631 33.203 24.1941 32.7992 24.1385C32.3846 24.0882 32.0277 24.3786 31.9765 24.7792L31.2637 30.4654C31.2126 30.8671 31.4988 31.2404 31.9025 31.2906C31.933 31.2961 31.9645 31.2961 31.995 31.2961V31.2972Z"
        fill="#151515"
      />
      <path
        d="M30.332 44.537C30.6977 44.537 31.0144 44.2641 31.0612 43.8897L31.775 38.209C31.8262 37.8019 31.54 37.434 31.1363 37.3838C30.7336 37.3281 30.3647 37.6185 30.3135 38.0246L29.5997 43.7052C29.5485 44.1124 29.8347 44.4792 30.2374 44.5305C30.2689 44.5359 30.3005 44.5359 30.331 44.5359L30.332 44.537Z"
        fill="#151515"
      />
      <path
        d="M40.9505 36.2698C41.3162 36.2698 41.6339 35.9969 41.6796 35.6225C41.7308 35.2154 41.4446 34.8486 41.0419 34.7973L35.3755 34.0779C34.9663 34.0277 34.6039 34.3181 34.5528 34.7187C34.5016 35.1258 34.7878 35.4937 35.1916 35.5439L40.858 36.2622C40.8885 36.2622 40.9201 36.2677 40.9505 36.2677V36.2698Z"
        fill="#151515"
      />
      <path
        d="M27.7552 34.5985C28.1219 34.5985 28.4386 34.3256 28.4854 33.9512C28.5355 33.5505 28.2493 33.1816 27.8466 33.1259L22.1813 32.412C21.7764 32.3618 21.4086 32.6456 21.3575 33.0539C21.3063 33.461 21.5925 33.8278 21.9963 33.8791L27.6616 34.593C27.6921 34.5985 27.7236 34.5985 27.7541 34.5985V34.5996L27.7552 34.5985Z"
        fill="#151515"
      />
      <path
        d="M11.4671 1.40796H11.4595H11.4671ZM8.38737 1.48001C9.33304 1.48001 10.2755 1.68086 11.1645 2.08257C11.1645 2.08257 11.1645 2.08257 11.1656 2.08257C14.661 3.65447 16.252 7.85167 14.7111 11.4365C13.958 13.1863 12.5836 14.5192 10.8413 15.1992C9.13499 15.8684 7.2752 15.8182 5.60476 15.065C2.10826 13.4876 0.517247 9.29586 2.05819 5.71105C2.81016 3.96122 4.18569 2.62292 5.92905 1.94285C6.72564 1.63065 7.55704 1.48001 8.38737 1.48001ZM8.38192 17.1434C9.3918 17.1434 10.4028 16.9545 11.3746 16.5746C13.4879 15.7494 15.1518 14.1327 16.0627 12.0205C17.9203 7.69994 15.993 2.63383 11.7685 0.732264C9.7346 -0.182495 7.47325 -0.24253 5.39581 0.565253C3.28246 1.3905 1.61746 3.00716 0.707698 5.12486C-1.14992 9.44432 0.776252 14.5061 5.00187 16.4131C6.08466 16.8988 7.23274 17.1434 8.38192 17.1434V17.1423V17.1434Z"
        fill="#151515"
      />
    </svg>
  );
};
