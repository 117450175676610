import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import theme from "../theme";

export const CategoryWrapper = styled(Box)({
  paddingTop: "120px",

  "@media (max-width: 1148px)": {
    marginTop: "70px",
    paddingTop: 0,
  },
});
export const CategoryTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  "@media (max-width: 1148px)": {
    "& img": {
      display: "none",
    },
  },
});

export const CategoryTopInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  marginRight: "15px",

  "@media (max-width: 1148px)": {
    marginRight: 0,
  },

  [theme.breakpoints.down("lg")]: {
    "& h1": {
      fontSize: "48px",
      lineHeight: "56px",

      "& strong": {
        fontSize: "48px",
        lineHeight: "56px",
      },
    },
  },

  "@media (max-width: 375px)": {
    maxWidth: "100%",

    "& h1": {
      fontSize: "32px",
      lineHeight: "42px",

      "& strong": {
        fontSize: "32px",
        lineHeight: "42px",
      },
    },
  },
});
export const CategoryTopDescription = styled(Typography)({
  marginTop: "44px",
  maxWidth: "540px",
  width: "100%",

  "& h3": {
    marginBottom: "44px",
  },

  "@media (max-width: 1148px)": {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "24px",
  },
});

export const CategoryDoubleBlock = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "120px",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: "60px",
  },
});
