import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  CategoryApproachesItem,
  CategoryApproachesList,
  CategoryApproachesWrapper,
  CategoryApproachesItemTop,
  CategoryApproachesLess,
  CategoryApproachesRowImage,
} from "./style";
import Cube from "../../assets/icons/Cube";
import { HOST_IMAGE_URL } from "../../constants";
import MediumArrow from "../../assets/icons/MediumArrow";
import CategoryApproachesLessImage from "../../assets/images/category-approaches-less.svg";
import ApproachesImage from "../../assets/images/approach-image.svg";
import ArrowHeading from "../arrowHeading";

const CategoryApproaches = ({ element }) => {
  return (
    <CategoryApproachesWrapper>
      <ArrowHeading
        minWidth="130px"
        dangerouslySetInnerHTML={{
          __html: element.title,
        }}
      />
      {element?.approaches?.length > 3 ? (
        <CategoryApproachesList>
          {element?.approaches?.map((item) => {
            return (
              <CategoryApproachesItem key={item.id}>
                <CategoryApproachesItemTop>
                  <img
                    className="icon"
                    src={`${
                      HOST_IMAGE_URL + item?.icon?.data?.attributes?.url
                    }`}
                    alt={item?.icon?.data?.attributes?.alternativeText}
                    title={item?.icon?.data?.attributes?.caption}
                  />
                  <img
                    className="hoverIcon"
                    src={`${
                      HOST_IMAGE_URL + item?.hoverIcon?.data?.attributes?.url
                    }`}
                    alt={item?.hoverIcon?.data?.attributes?.alternativeText}
                    title={item?.hoverIcon?.data?.attributes?.caption}
                  />
                  <Box className="cube">
                    <Cube />
                  </Box>
                </CategoryApproachesItemTop>
                <Typography
                  variant="body2"
                  component="div"
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
              </CategoryApproachesItem>
            );
          })}
          {element?.isShowApproachesImage === true && (
            <CategoryApproachesRowImage>
              <ApproachesImage />
            </CategoryApproachesRowImage>
          )}
        </CategoryApproachesList>
      ) : (
        <CategoryApproachesLess>
          <CategoryApproachesList className="vertical">
            {element?.approaches?.map((item) => {
              return (
                <CategoryApproachesItem key={item.id} className="vertical">
                  <Box className="titleArrow">
                    <MediumArrow />
                  </Box>
                  <Box>
                    <Typography
                      variant="h3"
                      component="div"
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    />
                    <Typography
                      variant="body2"
                      component="div"
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </Box>
                </CategoryApproachesItem>
              );
            })}
          </CategoryApproachesList>
          <CategoryApproachesLessImage className="lessImage" />
        </CategoryApproachesLess>
      )}
    </CategoryApproachesWrapper>
  );
};

export default CategoryApproaches;
