import React from "react";

export const PineChartsMobileIcon = () => {
  return (
    <svg
      width="343"
      height="442"
      viewBox="0 0 343 442"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.457 79.6934C96.0369 79.6934 37.6338 138.133 37.6338 210.598C37.6338 283.063 96.0369 341.503 168.457 341.503C240.877 341.503 299.28 283.063 299.28 210.598C299.28 138.133 240.877 79.6934 168.457 79.6934ZM168.457 248.584C147.432 248.584 130.495 231.636 130.495 210.598C130.495 189.56 147.432 172.612 168.457 172.612C189.482 172.612 206.419 189.56 206.419 210.598C206.419 231.636 190.066 248.584 168.457 248.584Z"
        fill="#5A32FB"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M206.422 214.105C205.838 225.209 199.998 234.559 191.821 240.988L247.304 314.622C275.922 292.999 295.195 260.273 298.699 222.287L206.422 214.105Z"
        fill="#EBF2F7"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M134.919 228.372C129.694 218.624 128.334 202.201 138.221 188.441L50.6811 155.225C31.7011 186.272 35.2075 236.558 52.4435 270.584L134.919 228.372Z"
        fill="#EBF2F7"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M133.806 178.704C139.167 168.965 148.496 163.095 158.654 160.874L140.78 70.4073C105.607 77.3919 74.0084 98.4447 54.313 131.107L133.806 178.704Z"
        fill="#EBF2F7"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M165.738 172.185C176.788 171.017 186.935 175.318 194.565 182.388L258.516 115.985C232.677 91.1049 197.348 77.2039 159.307 79.7123L165.738 172.185Z"
        fill="white"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M206.748 182.244C214.89 189.811 218.318 200.29 218.007 210.69L310.053 215.284C311.813 179.445 299.067 143.665 272.178 116.623L206.748 182.244Z"
        fill="#EDEDF8"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M240.286 328.082L188.157 251.892C170.809 263.777 147.265 259.367 135.388 242.007C134.728 241.043 134.068 240.078 133.408 239.114L52.3171 284.042C54.1447 287.748 56.4542 291.123 58.7637 294.498C99.3448 353.811 180.529 369.019 240.286 328.082Z"
        fill="#EDEDF8"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M85 61H117V123"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <circle cx="117" cy="123" r="5" fill="#151515" />
      <path
        d="M257 45H225V180"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(-1 0 0 1 230 175)"
        fill="#151515"
      />
      <path
        d="M163 16L195 16V108"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <circle cx="195" cy="108" r="5" fill="#151515" />
      <path
        d="M164 325L164 426L198 426"
        stroke="#151515"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(-1 0 0 1 169 320)"
        fill="#151515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0005 206.5L1.28516 206.5L1.28517 372H26.2891V373H0.289062V372H0.285171L0.285156 206C0.285156 205.724 0.509014 205.5 0.785156 205.5H51.0005V206.5Z"
        fill="#151515"
      />
      <circle
        cx="51.001"
        cy="206"
        r="5"
        transform="rotate(-90 51.001 206)"
        fill="#151515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.003 259.332L302.855 259.332L302.855 368.342H284.892V369.002H303.714V368.342H303.855L303.855 258.832C303.855 258.556 303.632 258.332 303.355 258.332H267.003V259.332Z"
        fill="#151515"
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 270.996 264)"
        fill="#151515"
      />
    </svg>
  );
};
