import React from "react";
import Typography from "@mui/material/Typography";
import {
  CategoryOurWorksList,
  CategoryOurWorksWrapper,
  SBtnContainer,
  SBtnWrapper,
  SLinkCases,
} from "./style";
import CasesPreview from "../casesPreview";
import { Link } from "gatsby";
import ArrowRight from "../../assets/icons/ArrowRight";
import ArrowHeading from "../arrowHeading";
import theme from "../../theme";
import palette from "../../theme/palette";

const CategoryOurWorks = ({ element, cases, slug }) => {
  const limitCases = cases?.data?.slice(0, 4);

  return (
    <CategoryOurWorksWrapper>
      <ArrowHeading
        dangerouslySetInnerHTML={{
          __html: element.title,
        }}
      />
      <Typography
        variant="body2"
        component="div"
        dangerouslySetInnerHTML={{
          __html: element.description,
        }}
        maxWidth="390px"
        marginTop="33px"
        sx={{ [theme.breakpoints.down("md")]: { marginTop: "16px" } }}
      />
      <Link to="/#footer">
        <SBtnContainer
          className="btnOutline"
          type="button"
          endIcon={<ArrowRight />}
        >
          Let's create something together
        </SBtnContainer>
      </Link>
      <CategoryOurWorksList>
        {limitCases?.map((item, index) => {
          return <CasesPreview data={item} key={index} />;
        })}
      </CategoryOurWorksList>
      <SBtnWrapper>
        <SLinkCases to={`/cases?category=${slug}`}>
          <SBtnContainer
            type="button"
            endIcon={<ArrowRight color={palette.secondary} />}
          >
            See all works
          </SBtnContainer>
        </SLinkCases>
      </SBtnWrapper>
    </CategoryOurWorksWrapper>
  );
};

export default CategoryOurWorks;
