import React from "react";
import Typography from "@mui/material/Typography";
import {
    CategoryDevelopmentApproachesItem, CategoryDevelopmentApproachesItemContent,
    CategoryDevelopmentApproachesItemInfo,
} from "./style";
import Box from "@mui/material/Box";
import MediumArrow from "../../assets/icons/MediumArrow";
import { Slider } from "../slider";

const CategoryDevelopmentApproachesSlider = ({ element }) => {
  return (
      <Slider
          settings={{
              autoplay: false,
          }}
          isArrowBox
          portalIdNext="development-approaches-next-arrow"
          portalIdPrev="development-approaches-prev-arrow"
          width="100%"
          margin="0px"
          wrapperProps={{
              marginTop: "26px",
              marginBottom: "68px",
          }}
      >
          {element?.developmentApproaches?.map((item) => {
              return (
                  <CategoryDevelopmentApproachesItem key={item.id}>
                      <CategoryDevelopmentApproachesItemInfo>
                          <Box className="titleArrow"><MediumArrow /></Box>
                          <CategoryDevelopmentApproachesItemContent>
                              <Typography
                                  variant="h2"
                                  component="div"
                                  className="title"
                              >
                                  {item.title}
                              </Typography>
                              <Typography
                                  variant="body2"
                                  component="div"
                                  className="description"
                                  dangerouslySetInnerHTML={{
                                      __html: item.description,
                                  }}
                              />
                          </CategoryDevelopmentApproachesItemContent>
                      </CategoryDevelopmentApproachesItemInfo>
                  </CategoryDevelopmentApproachesItem>
              );
          })}
      </Slider>
  );
};

export default CategoryDevelopmentApproachesSlider;
