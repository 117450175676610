import React from "react";

export const AltArrowDownIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.767 16.6857C21.4192 17.0631 20.8082 17.1068 20.4024 16.7833L12 10.0854L3.59755 16.7833C3.19176 17.1068 2.58082 17.0631 2.23299 16.6857C1.88517 16.3083 1.93216 15.7401 2.33796 15.4167L11.3702 8.21667C11.7326 7.92778 12.2674 7.92778 12.6298 8.21667L21.662 15.4167C22.0678 15.7401 22.1148 16.3083 21.767 16.6857Z"
        fill="#1C274C"
      />
    </svg>
  );
};
