import Box from "@mui/material/Box";
import React from "react";
import { MalevichFiguresIcon } from "../../assets/icons/MalevichFiguresIcon";
import { useBreakpointValue } from "../../hooks/use-breakpoint-value";
import ArrowHeading from "../arrowHeading";
import {
  CategoryInfrastructureWrapper,
  CategoryInfrastructureList,
  CategoryInfrastructureItem,
} from "./style";

const CategoryRightInfo = ({ element }) => {
  const isTablet = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  return (
    <CategoryInfrastructureWrapper>
      <ArrowHeading maxWidth="54px" widthWrapper="479px" className="title">
          {element?.title}
      </ArrowHeading>
      {!isTablet && (
        <Box margin="-8px 0px -27px -65px">
          <MalevichFiguresIcon />
        </Box>
      )}
      <CategoryInfrastructureList>
        {element?.rightInfoItems?.map((item) => {
          return (
            <CategoryInfrastructureItem key={item.id}>
              {item.title}
            </CategoryInfrastructureItem>
          );
        })}
      </CategoryInfrastructureList>
    </CategoryInfrastructureWrapper>
  );
};

export default CategoryRightInfo;
