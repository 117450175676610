import React from "react";
import {
  CategoryAdvantagesWrapper,
  CategoryAdvantagesItem,
  CategoryAdvantagesAnimationImage,
} from "./style";
import Typography from "@mui/material/Typography";
import malevichAnimation from "../../assets/animations/malevichAnimation.json";
import LottieIcon from "../lottieIcons";

const CategoryAdvantagesSlider = ({ element }) => {
  const logoAnimation = {
    animationData: malevichAnimation,
    loop: 0,
    autoplay: true,
  };

  return (
    <CategoryAdvantagesWrapper>
      {element?.advantages?.map((item) => {
        return (
          <CategoryAdvantagesItem key={item.id}>
            <Typography
              variant="h4"
              component="h2"
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
            />
            <Typography
              variant="body2"
              component="div"
              className="description"
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          </CategoryAdvantagesItem>
        );
      })}
      {element?.isShowMalevichImage === true && (
        <CategoryAdvantagesAnimationImage>
          <LottieIcon options={logoAnimation} replayInView disableOnHover />
        </CategoryAdvantagesAnimationImage>
      )}
    </CategoryAdvantagesWrapper>
  );
};

export default CategoryAdvantagesSlider;
