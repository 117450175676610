import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../theme/palette";
import theme from "../../theme";

export const CategoryAdvantagesWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  paddingTop: "120px",
});

export const CategoryAdvantagesItem = styled(Box)({
  maxWidth: "364px",
  width: "100%",
  marginBottom: "44px",
  padding: "26px",
  border: `1px solid ${palette.amethystSmoke}`,

    "& .title": {
        display: "flex",
        alignItems: "center",
        height: "64px",
        marginBottom: "12px",
        letterSpacing: "-0.01em",
    },

  "& .description": {
    paddingTop: "18px",
    borderTop: `1px solid ${palette.amethystSmoke}`,

    "& p": {
      margin: "0 0 18px",
      padding: 0,

      "& strong": {
        color: palette.primary,
      },

      "&:last-of-type": {
        margin: 0,
      },
    },

    "& ul": {
      marginTop: "20px",

      "& li": {
        position: "relative",
        marginBottom: 0,
        paddingLeft: "20px",
        listStyle: "none",

        "& p": {
          margin: 0,
          padding: 0,
        },

        "&:before": {
          content: "'•'",
          position: "absolute",
          top: 0,
          left: 0,
        },
      },
    },
  },

  "&:hover": {
    color: palette.secondary,
    backgroundColor: palette.primary,

    "& .description": {
      "& p": {
        "& strong": {
          color: palette.secondary,
        },
      },
    },
  },

    [theme.breakpoints.down("xl")]: {
        maxWidth: "49%",

        "& .title": {
            height: "auto",
        },
    },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
    marginBottom: 0,
    color: palette.secondary,
    background: palette.primary,
    border: `1px solid ${palette.amethystSmoke}`,
  },

  "@media (max-width: 375px)": {
    padding: 16,
  },
});

export const CategoryAdvantagesAnimationImage = styled(Box)({
  display: "flex",
  justifyContent: "center",
  maxWidth: "622px",
  width: "100%",
  height: "390px",

  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
});
