import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../theme/palette";
import Button from "@mui/material/Button";
import theme from "../../theme";
import { Link } from "gatsby";

export const CategoryOurWorksWrapper = styled(Box)({
  paddingTop: "120px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "60px",
  },
});

export const CategoryOurWorksList = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "44px",
  [theme.breakpoints.down("lg")]: {
    "& img": {
      width: "100% !important",
      marginLeft: "0px !important",
    },
  },
});

export const SBtnContainer = styled(Button)({
  "&.MuiButtonBase-root.MuiButton-root": {
    justifyContent: "space-between",
    maxWidth: 264,
    width: "100%",
    height: 42,
    padding: "0 26px",
    color: palette.secondary,
    fontSize: 16,
    lineHeight: "24px",
    backgroundColor: palette.purpleBlue,
    border: `1px solid ${palette.purpleBlue}`,
    borderRadius: 0,

    "&:hover": {
      backgroundColor: palette.secondary,
      color: palette.purpleBlue,

      "& svg path": {
        fill: palette.purpleBlue,
      },
    },

    [theme.breakpoints.down("sm")]: {
      height: 56,
      padding: "0 16px",
    },

    [theme.breakpoints.down("xl")]: {
      marginTop: "16px",
    },

    "&:hover a": {
      color: palette.purpleBlue,
    },

    "@media (max-width: 575px)": {
      width: "100%",
    },

    "@media (max-width: 450px)": {
      maxWidth: "unset",
    },
  },

  "& a": {
    color: palette.secondary,
    textDecoration: "none",
  },

  "&.btnOutline": {
    "&.MuiButtonBase-root.MuiButton-root": {
      maxWidth: "465px",
      width: "100%",
      margin: "33px 0px -16px 0px",
      color: palette.purpleBlue,
      backgroundColor: palette.secondary,

      "&:hover": {
        color: palette.secondary,
        backgroundColor: palette.purpleBlue,

        "& svg path": {
          fill: palette.secondary,
        },
      },

      "&:hover a": {
        color: palette.secondary,
      },

      [theme.breakpoints.down("md")]: {
        margin: "26px 0px -28px 0px",
      },

      "@media (max-width: 450px)": {
        maxWidth: "unset",
      },
    },

    "& a": {
      color: palette.purpleBlue,
    },
  },
});

export const SBtnWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: -68,

  [theme.breakpoints.down("xl")]: {
    justifyContent: "flex-start",
    marginTop: 0,
  },
});

export const SLinkCases = styled(Link)({
  maxWidth: 264,
  width: "100%",

  "@media (max-width: 450px)": {
    maxWidth: "unset",
  },
});
