import React from "react";
import { CategoryAdvantagesItem } from "./style";
import Typography from "@mui/material/Typography";
import { Slider } from "../slider";

const CategoryAdvantagesSlider = ({ element }) => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      portalIdNext="advantages-next-arrow"
      portalIdPrev="advantages-prev-arrow"
      width="100%"
      margin="0px"
      wrapperProps={{
        marginBottom: "68px",
      }}
    >
      {element?.advantages?.map((item) => {
        return (
          <CategoryAdvantagesItem key={item.id}>
            <Typography
              variant="h4"
              component="h2"
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
            />
            <Typography
              variant="body2"
              component="div"
              className="description"
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          </CategoryAdvantagesItem>
        );
      })}
    </Slider>
  );
};

export default CategoryAdvantagesSlider;
