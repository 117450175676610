import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import {
  CategoryTechnologyStackItem,
  CategoryTechnologyStackList,
  CategoryTechnologyStackListMobile,
  CategoryTechnologyStackWrapper,
  TechnologyStackInfo,
} from "./style";
import { HOST_IMAGE_URL } from "../../constants";
import ArrowHeading from "../arrowHeading";
import { ArrowTechnologyStackIcon } from "../../assets/icons/ArrowTechnologyStackIcon";
import { CursorIcon } from "../../assets/icons/CursorIcon";
import theme from "../../theme";
import { useBreakpointValue } from "../../hooks/use-breakpoint-value";

const CategoryTechnologyStack = ({ element, stackTechnologies }) => {
  const isMobile = useBreakpointValue([
    "smallMobile",
    "mobile",
    "bigMobile",
    "tablet",
  ]);

  const StackTechnologyWrapper = useMemo(() => {
    if (isMobile && stackTechnologies?.data?.length <= 10) {
      return React.Fragment;
    }
    if (isMobile) {
      return CategoryTechnologyStackListMobile;
    }
    return React.Fragment;
  }, [isMobile, stackTechnologies?.data?.length]);

  return (
    <CategoryTechnologyStackWrapper>
      <ArrowHeading
        dangerouslySetInnerHTML={{
          __html: element.title,
        }}
      />
      <CategoryTechnologyStackList
        sx={{
          ...(stackTechnologies?.data?.length <= 10 && {
            [theme.breakpoints.down("xl")]: { height: "100%" },
          }),
        }}
      >
        <StackTechnologyWrapper>
          {stackTechnologies?.data?.map((item, index) => {
            return (
              <CategoryTechnologyStackItem key={index}>
                <img
                  src={`${
                    HOST_IMAGE_URL +
                    item?.attributes?.icon?.data?.attributes?.url
                  }`}
                  alt={
                    item?.attributes?.icon?.data?.attributes?.alternativeText
                  }
                  title={item?.attributes?.icon?.data?.attributes?.caption}
                />
              </CategoryTechnologyStackItem>
            );
          })}
        </StackTechnologyWrapper>
      </CategoryTechnologyStackList>
      {stackTechnologies?.data?.length > 10 && (
        <TechnologyStackInfo>
          <Box className="firstArrowIcon">
            <ArrowTechnologyStackIcon />
          </Box>
          <Box className="cursorIcon">
            <CursorIcon />
          </Box>
          <Box className="secondArrowIcon">
            <ArrowTechnologyStackIcon />
          </Box>
        </TechnologyStackInfo>
      )}
    </CategoryTechnologyStackWrapper>
  );
};

export default CategoryTechnologyStack;
