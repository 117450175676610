import { styled } from "@mui/system";
import palette from "../../theme/palette";
import theme from "../../theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const CategoryWorksLikeThisWrapper = styled(Box)({
  paddingTop: "120px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "60px",
  },
});

export const CategoryWorksLikeThisContent = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "73px",
  [theme.breakpoints.down("xl")]: {
    flexDirection: "column-reverse",
    marginTop: "16px",
  },
});

export const CategoryWorksLikeThisSections = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    overflowX: "scroll",
    overflowY: "hidden",
    scrollbarWidth: "none",
    width: "auto",
    whiteSpace: "nowrap",
    marginRight: "-16px",
    cursor: "all-scroll",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    svg: {
      minWidth: "343px",
    },
  },

  svg: {
    marginLeft: "105px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0px",
    },
  },
});

export const CategoryWorksLikeThisSectionsWrapper = styled(Box)({});

export const CategoryWorksLikeThisSectionsItem = styled(Typography)({
  padding: "0 8px",
  textTransform: "uppercase",
  borderRight: `1px solid ${palette.primary}`,
  borderLeft: `1px solid ${palette.primary}`,
  position: "absolute",
  height: "42px",
  display: "flex",
  alignItems: "center",

  "&:nth-of-type(1)": {
    top: "228px",
    right: "calc(100% - 106px)",
  },
  "&:nth-of-type(2)": {
    top: "-19px",
    right: "calc(100% - 224px)",
  },
  "&:nth-of-type(3)": {
    top: "17px",
    left: "453px",
  },
  "&:nth-of-type(4)": {
    top: "130px",
    left: "641px",
  },
  "&:nth-of-type(5)": {
    left: " 616px",
    top: " 401px",
  },
  "&:nth-of-type(6)": {
    top: " 500px",
    right: "calc(100% - 251px)",
  },

  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    lineHeight: "22px",
    height: "32px",

    "&:nth-of-type(1)": {
      top: "356px",
      right: "unset",
      left: "26px",
    },
    "&:nth-of-type(2)": {
      top: "45px",
      right: "calc(100% - 86px)",
    },
    "&:nth-of-type(3)": {
      top: "1px",
      left: "unset",
      right: "calc(100% - 163px)",
    },
    "&:nth-of-type(4)": {
      top: "28px",
      left: "257px",
    },
    "&:nth-of-type(5)": {
      left: "unset",
      top: "353px",
      right: "calc(100% - 286px)",
    },
    "&:nth-of-type(6)": {
      top: "409px",
      right: "unset",
      left: "198px",
    },
  },
});

export const CategoryWorksLikeThisDescription = styled(Typography)({
  maxWidth: "344px",
  [theme.breakpoints.down("xl")]: {
    marginBottom: "30px",
    fontSize: "16px",
    lineHeight: "22px",
  },
});

export const CategoryWorksLikeThisList = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  marginTop: "44px",
});

export const CategoryWorksLikeThisListItem = styled(Box)({
  maxWidth: "380px",
  width: "100%",
  padding: "20.5px 26px",

  "& .title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "74px",
    borderBottom: `1px solid ${palette.amethystSmoke}`,

    "& .MuiTypography-root": {
      maxWidth: "228px",
    },
  },

  "& .description": {
    paddingTop: "25px",
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "49%",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "unset",
    padding: "16px 0px 0px 0px",

    "& .title": {
      height: "63px",

      "& .MuiTypography-root": {
        fontSize: "16px",
        lineHeight: "22px",
      },
    },

    "& .description": {
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
});

export const CategoryWorksRightWrapper = styled(Box)({
  svg: {
    width: "65px",
    height: "auto",
    marginBottom: "125px",
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
  },
});

export const TechnologyStackInfo = styled(Box)({
  display: "none",

  [theme.breakpoints.down("xl")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "16px 0px 0px 0px",

    "& .firstArrowIcon": {
      transform: "rotate(180deg)",
    },

    "& .cursorIcon": {
      margin: "0 12px",
    },
  },

  "@media (max-width: 375px)": {
    "& .firstArrowIcon svg, & .secondArrowIcon svg": {
      width: "100%",
      maxWidth: "140px",
      minWidth: "unset",
    },

    "& svg": {
      minWidth: "unset",
    },

    "& .cursorIcon": {
      margin: "0 6px",
    },
  },
});
